<template>
  <div id="center">
    <div class="base-info-row">
      <div class="base-info">
        <userBasicInfo
          :userData="userData"
          :is-self="true"
          :user-id="staffId"
        ></userBasicInfo>
      </div>
      <div class="resume-part">
        <div class="part-title">简历</div>
        <upload></upload>
      </div>
    </div>
    <div class="part">
      <div class="part-title">技能</div>
      <staffingskill
        :skillDept="userData.skillDept"
        :is-self="true"
        :user-id="staffId"
      />
    </div>
    <div class="part">
      <div class="part-title">证书</div>
      <Certification :is-self="true" :user-id="staffId"></Certification>
    </div>
    <div class="part">
      <div class="part-title">项目经历</div>
      <work-experience
        :is-self="true"
        :user-id="staffId"
        :show-work-experience-col="showWorkExperienceCol"
      />
    </div>
  </div>
</template>
<script>
import userBasicInfo from "./components/UserBasicInfo.vue";
import { defineComponent, reactive, toRefs } from "vue";
import upload from "@/components/upload.vue";
import staffingskill from "./components/StaffingSkill.vue";
import Certification from "./components/Certification.vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import moment from "moment";
import WorkExperience from "@/views/home/UserInfo/components/WorkExperience";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default defineComponent({
  name: "userInfo",
  components: {
    WorkExperience,
    userBasicInfo,
    staffingskill,
    Certification,
    upload,
  },
  props: {
    showWorkExperienceCol: {
      type: Boolean,
      default: false,
    },
    userId: String,
    isHaveAdd: Boolean,
  },
  emits: ["add-emp"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const staffId = computed(() => store.state.app.user.id);
    const state = reactive({
      userData: {},
    });

    const getUserData = () => {
      $api.myUserInfo().then((res) => {
        state.userData = res.data;
        state.userData.onboardDate = moment(state.userData.onboardDate).format(
          "YYYY-MM-DD"
        );
      });
    };

    const addEmp = () => {
      emit("add-emp");
    };

    const init = () => {
      getUserData();
    };
    init();
    return {
      staffId,
      ...toRefs(state),
      addEmp,
    };
  },
});
</script>
<style lang="less" scoped>
#center {
  padding: 16px 16px 38px;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  .base-info-row {
    display: flex;

    .base-info {
      flex: 1;
      margin-top: 50px;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 30px 30px 40px;
    }

    .resume-part {
      width: 25%;
      margin-left: 18px;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 20px 20px 40px;
    }

    .btn-part {
      margin-top: 50px;
      width: 20%;
      margin-left: 18px;
      display: flex;
      flex-direction: column;

      .ant-button {
        width: 100%;
      }
    }
  }

  .part {
    margin-top: 16px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 25px 40px;
  }

  .part-title {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #2d3748;
    margin-left: 20px;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 26px;
      position: absolute;
      left: -20px;
      top: 2px;
      background: #3182ce;
      border-radius: 10px;
    }
  }
}
</style>
