<template>
  <div class="resume">
    <div class="downLoad-Resume-temp">
      <a-button type="link" @click="handleDownLoadResumeTemp" v-auth="['system:userInfo:download']"
        >下载简历模板</a-button
      >
    </div>
    <div
      class="dragger-list"
      v-show="fileList.length"
      v-for="(item, index) in fileList"
      :key="index"
    >
      <div class="icon">
        <FilePptFilled
          class="ppt"
          v-show="item.file_name.indexOf('ppt') !== -1"
        />
        <FileWordFilled
          class="word"
          v-show="item.file_name.indexOf('doc') !== -1"
        />
      </div>
      <p class="text">{{ item.file_name }}</p>
      <div class="operation-btn">
        <VerticalAlignBottomOutlined
          class="sign" v-auth="['system:userInfoCv:download']"
          @click="handleDownloadItemClick(item, item.file_name)"
        />
        <DeleteOutlined
          class="sign" v-auth="['system:userInfoCv:delete']"
          @click="handleDeleteItemClick(index, item)"
        />
      </div>
    </div>
    <a-upload
      class="resume-upload"
      name="file"
      :accept="accept"
      :multiple="true"
      action=""
      :showUploadList="false"
      :beforeUpload="beforeUpload"
      :customRequest="() => {}"
    >
      <a-button class="blue-border-btn" v-auth="['system:userInfo:import']">上传简历</a-button>
    </a-upload>
  </div>
</template>
<script>
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  FilePptFilled,
  FileWordFilled,
  VerticalAlignBottomOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

const { downloadFile } = require("../utils/common");
export default defineComponent({
  components: {
    FilePptFilled,
    FileWordFilled,
    VerticalAlignBottomOutlined,
    DeleteOutlined,
  },

  setup: function () {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const fileList = ref([]);
    const fileData = ref([]);
    let errorNum = 0;
    const accept = ref(`
        .doc, .DOC,
        .docx, .DOCX,
        .ppt, .PPT,
        .pptx, .PPTX
      `);
    const customRequest = () => {
      const formData = new FormData();
      fileData.value.forEach((item) => {
        formData.append("file", item);
      });
      $api
        .getUpload(formData)
        .then((res) => {
          getResume(store.state.app.user.id);
          message.success(res.msg);
          fileData.value = [];
          errorNum = 0;
        })
        .catch((err) => {
          console.log(err);
          fileData.value = [];
          errorNum = 0;
        });
    };
    const getResume = (userId) => {
      $api
        .getResumeList(userId)
        .then((res) => {
          fileList.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    onMounted(() => {
      getResume(store.state.app.user.id);
    });
    // 上传前文件格式
    let messageTimer = null;
    const beforeUpload = (file, list) => {
      if (list.length + fileList.value.length > 4) {
        clearTimeout(messageTimer);
        messageTimer = setTimeout(() => {
          message.error("简历仅可上传2份Word格式，2份PPT格式，请重新选择并上传");
        });
        return;
      }
      return new Promise(() => {
        const fileType = file.name.substring(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        );
        const isLt10M = file.size / 1024 / 1024 < 10;
        const isGt0KB = file.size > 0;
        if (!isLt10M) {
          message.error("文件最大10MB");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (!isGt0KB) {
          message.error("不能上传0KB的文件");
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else if (
          ![
            "doc",
            "DOC",
            "docx",
            "DOCX",
            "ppt",
            "PPT",
            "pptx",
            "PPTX",
          ].includes(fileType)
        ) {
          message.error(
            file.name +
              " 文件格式错误！" +
              "只能上传doc、docx、ppt、pptx格式的文件"
          );
          errorNum++;
          if (errorNum == list.length) errorNum = 0;
        } else {
          fileData.value.push(file);
        }
        if (
          fileData.value?.length &&
          fileData.value.length == list.length - errorNum
        ) {
          // 上传
          customRequest();
        }
      });
    };

    const handleDownloadItemClick = (item, file_name) => {
      $api.getOneCV(item.id).then((res) => {
        downloadFile(res.file, file_name);
      });
    };
    const handleDeleteItemClick = (index, item) => {
      // fileList.value.splice(index, 1);
      $api.removeResume(item.id).then((res) => {
        if (res.code === 200) {
          message.success(`删除成功`);
          getResume(store.state.app.user.id)
        }
        // else {
        // message.error(`${item.fileName} Resume Deleted failed.`);
        // }
      });
    };

    const handleDownLoadResumeTemp = () => {
      $api.getResumeTep().then((res) => {
        console.log()
        downloadFile(res.file, decodeURIComponent(res.filename));
      });
    };
    return {
      handleDownLoadResumeTemp,
      fileList,
      getResume,
      accept,
      beforeUpload,
      customRequest,
      handleDownloadItemClick,
      handleDeleteItemClick,
    };
  },
});
</script>
<style lang="less" scoped>
.downLoad-Resume-temp {
  position: absolute;
  top: -28px;
  right: 0;
}

.blue-border-btn {
  border-color: #3182ce !important;
  color: #fff !important;
}

.resume {
  position: relative;
  height: 100%;
  padding-top: 10px;

  .resume-upload {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  :deep(.ant-upload) {
    width: 100%;
  }

  .blue-border-btn {
    margin-left: 5px;
    width: 100%;
    height: 40px;
    background: #3182ce;
  }
}

:deep(.ant-btn.ant-btn-link) {
  color: #3182ce;

  > span {
    text-decoration: underline;
  }
}

.dragger-list {
  height: 54px;
  margin: 0 -10px;
  padding: 0 10px;
  position: relative;

  .icon {
    position: absolute;
    left: 10px;
    top: 15px;

    .ppt {
      color: #f16c41;
    }

    .word {
      color: #4f6bf6;
    }

    ::v-deep svg {
      width: 24px;
      height: 24px;
    }
  }

  .text {
    padding-left: 30px;
    margin-bottom: 0;
    line-height: 54px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2d3748;
    padding-right: 30px;
  }

  .operation-btn {
    display: none;
  }

  &:hover {
    width: calc(100% - 50px);
    background: #e9f6fe;

    .operation-btn {
      display: block;
      width: 60px;
      height: 54px;
      padding-right: 10px;
      background: #e9f6fe;
      padding-top: 15px;
      position: absolute;
      top: 0;
      right: -60px;
      color: #3182ce;
      text-align: right;

      ::v-deep svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
